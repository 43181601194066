import { Avatar, Tooltip } from "antd";
import { STACK_VIEW_PATH } from "configs/AppConfig";
import {
    EXTERNAL_INITIATOR_SOURCE,
    ROLE,
    USER_TYPE,
    VCS_PROVIDER,
    TTL,
    AUDIT_RESOURCE_TYPES_LINK,
    CURRENCY,
    CONTROL_POLICIES_STATUS,
    CONTROL_POLICIES_ENFORCEMENT_LEVEL,
    CONTROL_POLICIES_TARGETS_ALL_NAMESPACES_OPTION,
    ORGANIZATION_SETTINGS,
    IAC_STATE_STATUS,
    CLOUD_CREDS_PROVIDER_TYPE,
    VCS_CONTROL_MONKEY,
    CONTROL_POLICIES_MAPPING_KEY,
	ONE_HOUR_SINCE_TERMINATION_TIME,
} from "constants/AppConstant";
import { numberOperators, stringOperators } from "pages/app-pages/components/variables/ValueConditionRule";
import moment from "moment";
import localStorageHelper from "helpers/localStorageHelper";
import { ALLOW_USER_TYPES_BY_PATH, ORGANIZATION_ALLOWED_ACL_ROUTES, ORG_USER_HOME_PAGE, USER_ALLOWED_ACL_ROUTES } from "configs/ACLConfig";
import { ROUTES } from "configs/AppRoutesConfig";
const dateFormat = "YYYY-MM-DD HH:mm:ss";

class Utils {

	static async variableKeyValidator(_ , input) {
		// variables regex pattern whic does not allow spaces and name must not start with a digit and whitespace but allows underscore after first char
		const pattern = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
		if (pattern.test(input)) {
			return Promise.resolve();
		}

		return Promise.reject("Key must consist of: letters, digits, underscores (_) and must not start with a digit")
	}

	static async variableKeyValidatorWithDash(_ , input) {
		// variables regex pattern whic does not allow spaces and name must not start with a digit and whitespace but allows underscore AND dash after first char
		const pattern = /^[a-zA-Z_][a-zA-Z0-9_-]*$/;
		if (pattern.test(input)) {
			return Promise.resolve();
		}

		return Promise.reject("Key must consist of: letters, digits, underscores (_), hyphens (-) and must not start with a digit or an hyphen")
	}

	static async variableValueValidator(_ , input) {
		// variable value validator which does not allow ' char and spaces

		if (input.includes("'")) {
			return Promise.reject("Value can not contain the ' character");
		}

		if (!input.trim() && input.length > 0) {
			return Promise.reject("Value can not contain only spaces");
		}

		return Promise.resolve();
	}

	static async variableDisplayNameValidator(_ , input) {
		// variable optional displayName validator - allow anything but not empty spaces
		if (input && !input.trim()) {
			return Promise.reject("Display name can not contain only spaces");
		}

		return Promise.resolve();
	}

	static async variableValueAllowEmptyValidator(_ , input) {
		// variable value validator which does not allow ' char and allow empty (server will send null)

		if (input && input.includes("'")) {
			return Promise.reject("Value can not contain the ' character");
		}

		return Promise.resolve();
	}

	static conditionRuleChecker(operator, ruleValue) {
		// this function gets the conditionRule operator and rule value
		// then returns a function validating these rules with the current entered value
		const validator = async (_, inputValue) => {
			if (numberOperators.includes(operator)) {
				const isStringValue = isNaN(Number(inputValue));
				if (isStringValue) {
					return Promise.reject(`This variable value must be a number`);
				}

				const inputValueNumber = Number(inputValue);
				// Handle number
				switch (operator) {
					case "lt": {
						if (inputValueNumber < ruleValue) {
							return Promise.resolve();
						} else {
							return Promise.reject(`Value must be less than ${ruleValue}`);
						}
					}
					case "lte": {
						if (inputValueNumber <= ruleValue) {
							return Promise.resolve();
						} else {
							return Promise.reject(`Value must be less than or equal to ${ruleValue}`);
						}
					}
					case "gt": {
						if (inputValueNumber > ruleValue) {
							return Promise.resolve();
						} else {
							return Promise.reject(`Value must be greater than ${ruleValue}`);
						}
					}
					case "gte": {
						if (inputValueNumber >= ruleValue) {
							return Promise.resolve();
						} else {
							return Promise.reject(`Value must be greater than or equal to ${ruleValue}`);
						}
					}
				}
				// this should not happen
				return Promise.reject("Unknown number operator");
			}

			if (stringOperators.includes(operator)) {
				switch (operator) {
					case "ne": {
						if (ruleValue !== inputValue) {
							return Promise.resolve();
						} else {
							return Promise.reject(`Value must not be equal to '${ruleValue}'`);
						}
					}
					case "startsWith": {
						if (inputValue.startsWith(ruleValue)) {
							return Promise.resolve();
						} else {
							return Promise.reject(`Value must start with '${ruleValue}'`);
						}
					}

					case "contains": {
						if (inputValue.includes(ruleValue)) {
							return Promise.resolve();
						} else {
							return Promise.reject(`Value must include '${ruleValue}'`);
						}
					}

					case "in": {
						// ruleValue is an Array, we check if the selected value is in it
						if (Array.isArray(ruleValue) && ruleValue.includes(inputValue)) {
							return Promise.resolve();
						} else {
							return Promise.reject(
								`Selected value should be in these value options '${ruleValue.join(",")}'`
							);
						}
					}
				}
			}

			if (operator === "match") {
				// first try to validate and set regex
				try {
					const regexRule = new RegExp(ruleValue);
					const match = inputValue.match(regexRule);
					if (match && inputValue === match[0]) {
						// valid regex value - by Java Flavour since java is doing EXACT match by default
						return Promise.resolve();
					} else {
						return Promise.reject(`The value does not match the regex pattern '${ruleValue}'`);
					}
				} catch (e) {
					// this should not happen since it returns form server
					return Promise.reject('The regex rule value is not a valid regex')
				}
			}

			// Note: this validation only happens on add/edit variables modal to validate the default value
			//       on create stack from template there is regular required validation on the radio component of true/false
			if (operator === "boolean") {
				if (inputValue === 'true' || inputValue === 'false') {
					return Promise.resolve();
				}

				return Promise.reject(`Value must be 'true' or 'false'`);
			}
		}
		return validator;
	}

	static addEditVariableDefaultValueValidation = (conditionalRule, _,  input) => {
		let { operator, value: conditionValue } = conditionalRule;
		if ((operator && conditionValue) || operator === "boolean") {
			// the oeprator and condition value are set we need to check if the input if default value is not empty
			if (input && input.trim()) {
				// console.log('# running condition validator');
				if (operator === "in") {
					// we must make the condition value to be an array and filtered from empty values
					// the same functionality is done when add/edit model is sending it ot the server on "onSaveClick" function
					conditionValue = conditionValue.split(",").filter(i => !!i.trim()).map((i) => i.trim());
				}
				const validatorByOperation = Utils.conditionRuleChecker(operator, conditionValue);
				return validatorByOperation(_, input);
			}
			// console.log('# condition rule is set but input value is empty which is fine')
			// the input value is empty which is fine as well
		} else {
			// the operator and condition value are not set so we ignore the input value
			// console.log('# condition rules are both or either is not set so this is OK, validation of these fields will occuer')
		}
		return Promise.resolve();
	}

	static getRegionFromArn(arn) {
		let retVal = arn.split(":")[3];
		return retVal;
	}

	static getResourceNameFromArn(arn) {
		let retVal = arn.split(':').slice(5).join(':');
		return retVal;
	}

	static getCfnStackNameFromArn(stackArn) {
		let retVal = stackArn.split(":stack/")[1].split("/")[0];
		return retVal;
	}

	static calcTfResourceLogicalId(resource) {

		// Calc logical id
		let logicalId = resource.logicalResourceId

		if (resource.module) {
			logicalId = resource.module + '.' + resource.logicalResourceId;

			if (resource.indexKey) {
				// Both module and index.
				if (resource.module.includes('["')) {
					if (resource.indexKey !== '0') {
						logicalId = resource.module + '[' + resource.indexKey + '].' + resource.logicalResourceId;
					}

				} else {
					logicalId = resource.module + '[' + resource.indexKey + '].' + resource.logicalResourceId;
				}
			}
		} else {
			// No module.

			// Checking for index
			if (resource.indexKey) {
				logicalId = resource.logicalResourceId + '[' + resource.indexKey + ']';
			}
		}

		return logicalId;
	}
	
	static getLatestDriftSource(resource) {
		const {driftSource = {}} = resource;
		let {sources = []} = driftSource;
		// sort sources - latest is first
		sources = sources.sort((a, b) => moment(b?.cloudTrail?.eventTime).unix() - moment(a?.cloudTrail?.eventTime).unix());
		// return the first element which is the latest source
 		if (sources.length > 0) {
			return sources[0]?.cloudTrail || null;
		}

		return null;
	}

	static calcCost(actualMonthlyCost, expectedMonthlyCost) {
		// calc the diff and parse to float number with fixed precision of 2
		let costCalc = parseFloat(actualMonthlyCost - expectedMonthlyCost).toFixed(2);
		// convert the string to number again ("0.00" -> 0 for example)
		costCalc = parseFloat(costCalc);

		return costCalc;
	}

	static calcDriftCost(resource) {
		if (!resource.cost) {
			return 0;
		}

		// get the cost object
		const {cost = {}} = resource;
		// adding for each default values of 0 because of initial values 
		// in case of drift might be 0 and in case of infracost null (which does not return form server)
		const {expectedMonthlyCost = 0, actualMonthlyCost = 0} = cost;

		return this.calcCost(actualMonthlyCost, expectedMonthlyCost);
	}

	static calcInfracostCost(resource) {
		// adding for each default values of 0 because of initial values 
		// in case of drift might be 0 and in case of infracost null (which does not return form server)
		const {expectedMonthlyCost = 0, actualMonthlyCost = 0} = resource;
		
		// NOTE: in infracost we do the calculation opposite since the actual becomes expected 
		return this.calcCost(expectedMonthlyCost, actualMonthlyCost);
	}

	static showMoney(cost, currency = CURRENCY.USD) {
		// just a precaution if user sent a string
		const safeCost = parseFloat(cost);
		// when ever we show money related value, we should have it with 2 digits after ".", theLocaleString native method solve this with currency
		return safeCost.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency})
	}

	static makeid(length) {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() *
				charactersLength));
		}
		return result;
	}

	static buildVcsUrl(vcsProvider, vcsInfo, filePath, lineNumber, overrideVcsInfoPath) {
		let retVal;

		if (vcsInfo.providerType === VCS_PROVIDER.GITHUB_COM) {
			retVal = "https://github.com/" + vcsProvider.githubMetadata.orgName + "/" + vcsInfo.repoName;

			if (vcsInfo.branch?.length) {
				retVal = retVal + "/tree/" + vcsInfo.branch;
			} else {
				// main takes to the default branch.
				retVal = retVal + "/tree/main";
			}

			if (typeof overrideVcsInfoPath === 'string') { // accept also empty "" as truthy
				retVal = retVal + `${overrideVcsInfoPath ? "/" + overrideVcsInfoPath : ""}` ;
			} else {
				if (vcsInfo.path) {
					retVal = retVal + "/" + vcsInfo.path;
				}
			}


			if (filePath) {
				retVal = retVal + "/" + filePath;
			}

			if (lineNumber) {
				retVal = retVal + "#L" + lineNumber;
			}
		}
		else if (vcsInfo.providerType === VCS_PROVIDER.GITHUB_ENTERPRISE) {
			let originUrl;
			try {
				originUrl = new URL(vcsProvider?.githubMetadata?.httpUrl).origin;
			} catch (e) {
				console.log('could not construct vcsProvider URL of github-enterprise', e);
			}

			if (!originUrl) {
				return retVal;
			}

			retVal = originUrl + '/' + vcsProvider.githubMetadata.orgName + "/" + vcsInfo.repoName;

			if (vcsInfo.branch?.length) {
				retVal = retVal + "/tree/" + vcsInfo.branch;
			} else {
				// main takes to the default branch.
				retVal = retVal + "/tree/main";
			}

			if (overrideVcsInfoPath) {
				retVal = retVal + "/" + overrideVcsInfoPath;
			} else {
				if (vcsInfo.path) {
					retVal = retVal + "/" + vcsInfo.path;
				}
			}

			if (filePath) {
				retVal = retVal + "/" + filePath;
			}

			if (lineNumber) {
				retVal = retVal + "#L" + lineNumber;
			}
		} else if (vcsInfo.providerType === VCS_PROVIDER.GITLAB_COM) {
			// vcsInfo.repoName - Full repo name expected, for example: control-monkey-demo/terraform
			retVal = "https://gitlab.com/" + vcsInfo.repoName;
			if (vcsInfo.branch?.length) {
				retVal = retVal + "/-/tree/" + vcsInfo.branch;
			} else {
				retVal = retVal + "/-/tree/HEAD";
			}

			if (overrideVcsInfoPath) {
				retVal = retVal + "/" + overrideVcsInfoPath;
			} else {
				if (vcsInfo.path) {
					retVal = retVal + "/" + vcsInfo.path;
				}
			}

			if (filePath) {
				retVal = retVal + "/" + filePath;
			}

			if (lineNumber) {
				retVal = retVal + "#L" + lineNumber;
			}
		} else if (vcsInfo.providerType === VCS_PROVIDER.GITLAB_ONPREM) {
			// vcsInfo.repoName - Full repo name expected, for example: control-monkey-demo/terraform
			let originUrl;
			try {
				originUrl = new URL(vcsProvider?.gitlabMetadata?.dnsName).origin;
			} catch (e) {
				console.log('could not construct vcsProvider URL of gitlab-onprem', e);
			}

			if (!originUrl) {
				return retVal;
			}

			retVal = originUrl + '/' + vcsInfo.repoName;
			if (vcsInfo.branch?.length) {
				retVal = retVal + "/-/tree/" + vcsInfo.branch;
			} else {
				retVal = retVal + "/-/tree/HEAD";
			}

			if (overrideVcsInfoPath) {
				retVal = retVal + "/" + overrideVcsInfoPath;
			} else {
				if (vcsInfo.path) {
					retVal = retVal + "/" + vcsInfo.path;
				}
			}

			if (filePath) {
				retVal = retVal + "/" + filePath;
			}

			if (lineNumber) {
				retVal = retVal + "#L" + lineNumber;
			}
		} else if (vcsInfo.providerType === VCS_PROVIDER.BITBUCKET_CLOUD) {
			// vcsInfo.repoName - Full repo name expected, for example: control-monkey-demo/terraform
			retVal = "https://bitbucket.org/" + vcsInfo.repoName + "/src";
			if (vcsInfo.branch?.length) {
				retVal = retVal + "/" + vcsInfo.branch;
			} else {
				retVal = retVal + "/HEAD";
			}

			if (overrideVcsInfoPath) {
				retVal = retVal + "/" + overrideVcsInfoPath;
			} else {
				if (vcsInfo.path) {
					retVal = retVal + "/" + vcsInfo.path;
				}
			}

			if (filePath) {
				retVal = retVal + "/" + filePath;
			}

			if (lineNumber) {
				retVal = retVal + "#lines-" + lineNumber;
			}
		} else if (vcsInfo.providerType === VCS_PROVIDER.BITBUCKET_DATA_CENTER) {
			try {
				retVal = new URL(vcsProvider?.bitbucketDataCenterMetadata?.httpUrl) + '/';
			} catch (e) {
				console.log('could not construct vcsProvider commit/PR URL of bitbucket-data-center', e);
			}

			const [projectKey, repoName] = vcsInfo.repoName.split("/");

			retVal = retVal + 'projects/' + projectKey + '/repos/' + repoName;
			if (overrideVcsInfoPath) {
				retVal = retVal + "/browse/" + overrideVcsInfoPath;
			} else {
				if (vcsInfo.path) {
					retVal = retVal + "/browse/" + vcsInfo.path;
				}
			}

			if (filePath) {
				retVal = retVal + "/" + filePath;
			}

			if (lineNumber) {
				retVal = retVal + "#" + lineNumber;
			}
		} else if (vcsInfo.providerType === VCS_PROVIDER.AZURE_DEVOPS) {
			// split the project name and the repo name to build the url.
			let repoNameParts = vcsInfo.repoName.split("/");
			let organizationName = repoNameParts?.[0];
			let projectName = repoNameParts?.[1];
			let repoName = repoNameParts?.[2];

			retVal = `https://dev.azure.com/${organizationName}/${projectName}/_git/${repoName}?path=/`;

			if (overrideVcsInfoPath) {
				retVal = retVal + "/" + overrideVcsInfoPath;
			} else {
				if (vcsInfo.path) {
					retVal = retVal + vcsInfo.path;
				}
			}

			if (filePath) {
				retVal = retVal + "/" + filePath;
			}

			if (vcsInfo.branch?.length) {
				retVal = retVal + "&" + vcsInfo.branch;
			}

			if (lineNumber) {
				// &line=10&lineEnd=11&lineStartColumn=1
				retVal = retVal + "&line=" + lineNumber + "&lineEnd=" + (lineNumber + 1) + "&lineStartColumn=1";
			}

			// console.log(retVal);
		} else if (vcsInfo.providerType === VCS_PROVIDER.AZURE_DEVOPS_SERVER) {
			// same as azure-devops provider but the base url comes from the meta data
			// split the project name and the repo name to build the url.
			let repoNameParts = vcsInfo.repoName.split("/");
			let organizationName = repoNameParts?.[0];
			let projectName = repoNameParts?.[1];
			let repoName = repoNameParts?.[2];

			try {
				const baseUrl = new URL(vcsProvider?.azureDevopsMetadata?.httpUrl).origin;
				retVal = `${baseUrl}/${organizationName}/${projectName}/_git/${repoName}?path=/`;
			} catch (e) {
				console.log('could not construct vcsProvider VCS URL of azure-devops-server', e);
			}

			if (overrideVcsInfoPath) {
				retVal = retVal + "/" + overrideVcsInfoPath;
			} else {
				if (vcsInfo.path) {
					retVal = retVal + vcsInfo.path;
				}
			}

			if (filePath) {
				retVal = retVal + "/" + filePath;
			}

			if (vcsInfo.branch?.length) {
				retVal = retVal + "&" + vcsInfo.branch;
			}

			if (lineNumber) {
				// &line=10&lineEnd=11&lineStartColumn=1
				retVal = retVal + "&line=" + lineNumber + "&lineEnd=" + (lineNumber + 1) + "&lineStartColumn=1";
			}

			// console.log(retVal);
		}

		return retVal;
	}

	static getCodeLocationLink(codeLocation = {}, vcsInfo = {}, vcsProviders = []) {
		let vcsLink = "";
		const { fileName, lineNumber, codeLink, overrideDirectory } = codeLocation;
		const { providerId } = vcsInfo;

		if (codeLink) {
			// code link might be missing http prefix which is required for external linking
			vcsLink = codeLink.startsWith('http') ? codeLink : `https://${codeLink}`;
			return vcsLink;
		}

		if (providerId) {
			const vcsProvider = vcsProviders.find((provider) => provider.id === providerId);
			if (vcsProvider) {
				if (!overrideDirectory && fileName && lineNumber) {
					// preserve original behavior where fileName and lineNumber were only provided
					vcsLink = this.buildVcsUrl(vcsProvider, vcsInfo, fileName, lineNumber);
				} else if (fileName) {
					// filename is mandatory while line number and overrideDirectory are optional for new link type
					vcsLink = this.buildVcsUrl(vcsProvider, vcsInfo, fileName, lineNumber, overrideDirectory);
				}
			}
		}

		return vcsLink;
	}

	//method to handle steps
	static onStepChange = ({ valueClicked, currentStep, setCurrentStep, form }) => {
		switch (valueClicked) {
			case 'previous':
				setCurrentStep(currentStep - 1)
				break;
			case 'next':
				form.validateFields().then(values => {
					setCurrentStep(currentStep + 1);
				}).catch(function (err) {
					console.log("Error", err);
				});
				break;
			default:
				break;
		}
	};


	//method to build Url to a specific commit or pull request
	static buildVcsCommitOrPrUrl = ({ linkTo, vcsInfo, vcsProvider, triggerInfo, gitInfo }) => {
		let retVal;
		let baseUrl;
		switch (vcsInfo.providerType) {
			case VCS_PROVIDER.GITHUB_COM:
				baseUrl = "https://github.com/";
				let orgName = vcsProvider?.githubMetadata?.orgName || "";
				// if repoName includes "/" which means it is a public repo, no need to add organization
				orgName = vcsInfo.repoName.includes('/') ? "" : orgName + "/";
				if (linkTo === 'commit') {
					triggerInfo?.githubPullRequestNumber ?
						// check if we have pull request info -> direct to a conmit in a pr
						retVal = baseUrl + orgName + vcsInfo.repoName + '/pull/' + triggerInfo.githubPullRequestNumber + '/commits/' + gitInfo.headSha
						: retVal = baseUrl + orgName + vcsInfo.repoName + '/commit/' + gitInfo.headSha;
				}
				else if (linkTo === 'pr') retVal = baseUrl + orgName + "/" + vcsInfo.repoName + '/pull/' + triggerInfo.githubPullRequestNumber;
				break;
			case VCS_PROVIDER.GITHUB_ENTERPRISE:
				try {
					baseUrl = new URL(vcsProvider?.githubMetadata?.httpUrl).origin + '/';
				} catch (e) {
					console.log('could not construct vcsProvider commit/PR URL of github-enterprise', e);
				}

				if (linkTo === 'commit') {
					triggerInfo?.githubPullRequestNumber ?
						// check if we have pull request info -> direct to a conmit in a pr
						retVal = baseUrl + vcsProvider.githubMetadata.orgName + "/" + vcsInfo.repoName + '/pull/' + triggerInfo.githubPullRequestNumber + '/commits/' + gitInfo.headSha
						: retVal = baseUrl + vcsProvider.githubMetadata.orgName + "/" + vcsInfo.repoName + '/commit/' + gitInfo.headSha;
				}
				else if (linkTo === 'pr') retVal = baseUrl + vcsProvider.githubMetadata.orgName + "/" + vcsInfo.repoName + '/pull/' + triggerInfo.githubPullRequestNumber;
				break;
			case VCS_PROVIDER.AZURE_DEVOPS: {
				baseUrl = "https://dev.azure.com/";
				// split the project name and the repo name to build the url.
				let repoNameParts = vcsInfo.repoName.split("/");
				let organizationName = repoNameParts?.[0];
				let projectName = repoNameParts?.[1];
				let repoName = repoNameParts?.[2];

				retVal = `${baseUrl}${organizationName}/${projectName}/_git/${repoName}`;

				if (linkTo === 'commit') {
					triggerInfo?.azureDevopsPullRequestId ?
						retVal = retVal + "/commit/" + gitInfo.headSha + '?refName=' + gitInfo.branch
						: retVal = retVal + "/commit/" + gitInfo.headSha;
				}
				else if (linkTo === 'pr') {
					retVal = retVal + '/pullrequest/' + triggerInfo.azureDevopsPullRequestId;
				}

				break;
			}

			case VCS_PROVIDER.AZURE_DEVOPS_SERVER: {
				// same as azure-devops provider but the base url comes from the meta data
				// split the project name and the repo name to build the url.
				let repoNameParts = vcsInfo.repoName.split("/");
				let organizationName = repoNameParts?.[0];
				let projectName = repoNameParts?.[1];
				let repoName = repoNameParts?.[2];

				try {
					baseUrl = new URL(vcsProvider?.azureDevopsMetadata?.httpUrl).origin;
					retVal = `${baseUrl}/${organizationName}/${projectName}/_git/${repoName}`;
				} catch (e) {
					console.log('could not construct vcsProvider commit/PR URL of azure-devops-server', e);
				}

				if (linkTo === 'commit') {
					triggerInfo?.azureDevopsPullRequestId ?
						retVal = retVal + "/commit/" + gitInfo.headSha + '?refName=' + gitInfo.branch
						: retVal = retVal + "/commit/" + gitInfo.headSha;
				}
				else if (linkTo === 'pr') {
					retVal = retVal + '/pullrequest/' + triggerInfo.azureDevopsPullRequestId;
				}

				break;
			}

			case VCS_PROVIDER.GITLAB_COM:
				baseUrl = "https://gitlab.com/";
				if (linkTo === 'commit') {
					triggerInfo?.gitlabMergeRequestIid ?
						retVal = baseUrl + "/" + vcsInfo.repoName + '/-/merge_requests/' + triggerInfo.gitlabMergeRequestIid + '/diffs?commit_id=' + gitInfo.headSha
						: retVal = baseUrl + "/" + vcsInfo.repoName + '/commit/' + gitInfo.headSha
				}
				else if (linkTo === 'pr') retVal = baseUrl + "/" + vcsInfo.repoName + '/-/merge_requests/' + triggerInfo.gitlabMergeRequestIid;
				break;

			case VCS_PROVIDER.GITLAB_ONPREM:
				try {
					baseUrl = new URL(vcsProvider?.gitlabMetadata?.dnsName).origin + '/';
				} catch (e) {
					console.log('could not construct vcsProvider commit/PR URL of gitlab-onprem', e);
				}
				if (linkTo === 'commit') {
					triggerInfo?.gitlabMergeRequestIid ?
						retVal = baseUrl + "/" + vcsInfo.repoName + '/-/merge_requests/' + triggerInfo.gitlabMergeRequestIid + '/diffs?commit_id=' + gitInfo.headSha
						: retVal = baseUrl + "/" + vcsInfo.repoName + '/commit/' + gitInfo.headSha
				}
				else if (linkTo === 'pr') retVal = baseUrl + "/" + vcsInfo.repoName + '/-/merge_requests/' + triggerInfo.gitlabMergeRequestIid;
				break;

			case VCS_PROVIDER.BITBUCKET_CLOUD:
				baseUrl = "https://bitbucket.org/";
				//pr commit is same as commit, since there is no link to a commit inside a pull request
				if (linkTo === 'commit') retVal = baseUrl + "/" + vcsInfo.repoName + '/commits/' + gitInfo.headSha;
				else if (linkTo === 'pr') retVal = baseUrl + "/" + vcsInfo.repoName + '/pull-requests/' + triggerInfo.bitbucketPullRequestId;
				break;
			
			case VCS_PROVIDER.BITBUCKET_DATA_CENTER: {
				try {
					baseUrl = new URL(vcsProvider?.bitbucketDataCenterMetadata?.httpUrl) + '/';
				} catch (e) {
					console.log('could not construct vcsProvider commit/PR URL of bitbucket-data-center', e);
				}

				const [projectKey, repoName] = vcsInfo.repoName.split("/");
				
				if (linkTo === 'commit') {
					triggerInfo?.bitbucketDataCenterPullRequestId ?
						retVal = baseUrl + 'projects/' + projectKey + '/repos/' + repoName + '/pull-requests/' + triggerInfo.bitbucketDataCenterPullRequestId + '/commits/' + gitInfo.headSha
						: retVal = baseUrl + 'projects/' + projectKey + '/repos/' + repoName + '/commits/' + gitInfo.headSha
				}
				else if (linkTo === 'pr') retVal = baseUrl + 'projects/' + projectKey + '/repos/' + repoName + '/pull-requests/' + triggerInfo.bitbucketDataCenterPullRequestId + '/overview';
				break;
			}
				
			default:
				break;
		}

		return retVal;
	};

	static getProfileLinkFromVCSProvider = (initiator, vcsProvider) => {
		const username = initiator?.username || initiator.userName; // plan/deployment and report return userName while audit username
		if (!username) {
			return "N/A";
		}
		const isUsernameBot = username.endsWith(VCS_CONTROL_MONKEY.BOT_TOKEN);
		const isUsernameControlMonkeyBot = username === VCS_CONTROL_MONKEY.GITHUB_BOT;
		let retVal;
		let baseUrl;
		switch (vcsProvider.type) {
			case VCS_PROVIDER.GITHUB_COM:
				if (isUsernameControlMonkeyBot) {
					retVal = (
						<div className="d-flex align-items-center">
							<Avatar size="small" shape="circle" src={VCS_CONTROL_MONKEY.GITHUB_BOT_PROFILE_URL} />{" "}
							control-monkey (GitHub Bot)
						</div>
					)
				} else if (isUsernameBot) {
					const botUsername = username.split(VCS_CONTROL_MONKEY.BOT_TOKEN)[0];
					retVal = `${botUsername} (GitHub Bot)`;
				} else {
					// regular user
					retVal = (
						<a href={`https://github.com/${username}`} target="_blank" rel="noreferrer">
							<Avatar size="small" shape="circle" src={`https://github.com/${username}.png`} />{" "}
							{username} (GitHub)
						</a>
					);
				}
				
				break;
			case VCS_PROVIDER.GITHUB_ENTERPRISE:
				if (isUsernameControlMonkeyBot) {
					retVal = (
						<div className="d-flex align-items-center">
							<Avatar size="small" shape="circle" src={VCS_CONTROL_MONKEY.GITHUB_BOT_PROFILE_URL} />{" "}
							control-monkey (GitHub Bot)
						</div>
					)
				} else if (isUsernameBot) {
					const botUsername = username.split(VCS_CONTROL_MONKEY.BOT_TOKEN)[0];
					retVal = `${botUsername} (GitHub Bot)`;
				} else {
					try {
						baseUrl = new URL(vcsProvider?.githubMetadata?.httpUrl).origin + '/';
						retVal = (
							<a href={`${baseUrl}${username}`} target="_blank" rel="noreferrer">
								{username} (GitHub)
							</a>
						);
					} catch (e) {
						console.log('could not construct vcsProvider commit/PR URL of github-enterprise', e);
					}
				}

				break;
			case VCS_PROVIDER.AZURE_DEVOPS: {
				retVal = username + " (Azure DevOps User)";
				break;
			}
			case VCS_PROVIDER.AZURE_DEVOPS_SERVER: {
				// for future usage
				// try {
				// 	baseUrl = new URL(vcsProvider?.azureDevopsMetadata?.httpUrl).origin;
				// 	// retVal = `${baseUrl}/${organizationName}/${projectName}/_git/${repoName}`;
				// } catch (e) {
				// 	console.log('could not construct vcsProvider commit/PR URL of github-enterprise', e);
				// }
				
				retVal = username + " (Azure DevOps User)";
				break;
			}
			case VCS_PROVIDER.GITLAB_COM: {
				retVal = (
					<a href={`https://gitlab.com/${username}`} target="_blank" rel="noreferrer">
						{username} (Gitlab)
					</a>
				);
				break;
			}
			case VCS_PROVIDER.GITLAB_ONPREM:
				try {
					baseUrl = new URL(vcsProvider?.gitlabMetadata?.dnsName).origin + '/';
					retVal = (
						<a href={`${baseUrl}${username}`} target="_blank" rel="noreferrer">
							{username} (Gitlab)
						</a>
					);
				} catch (e) {
					console.log('could not construct vcsProvider commit/PR URL of gitlab-onprem', e);
				}

				break;
			case VCS_PROVIDER.BITBUCKET_CLOUD:
				retVal = (
					<a href={`https://bitbucket.org/${username}`} target="_blank" rel="noreferrer">
						{username} (BitBucket)
					</a>
				);

				break;
			case VCS_PROVIDER.BITBUCKET_DATA_CENTER: {
				// for future use
				// try {
				// 	baseUrl = new URL(vcsProvider?.bitbucketDataCenterMetadata?.httpUrl) + '/';
				// } catch (e) {
				// 	console.log('could not construct vcsProvider commit/PR URL of bitbucket-data-center', e);
				// }
				
				retVal = username + " (Bitbucket Data Center User)";
				break;
			}

			default:
				break;
		}
		
		return retVal;

	}
	// on the Latest Deployment Status column in stack table we use this code with the initiator prop only
	static getInitiatorProfileLink = ({initiator, vcsProviderId, vcsProviders = [], skipLink = false}) => {
		let switchAction;
		let username;
		let vcsProvider;
		if (vcsProviderId) {
			vcsProvider = vcsProviders.find((provider) => provider.id === vcsProviderId);
			
		} 
		if (!vcsProvider && vcsProviders.length === 1) {
			// VCS Providers contain one provider, we take it as default
			vcsProvider = vcsProviders[0];
		}


		// could not find vcsProvider we continue to old function to render username and link (if available)
		if (initiator.externalSource && initiator.username) {
			// comes from audit page
			switchAction = initiator.externalSource;
			username = initiator.username;
		}

		if (initiator.userType && initiator.userName) {
			// comes from plan/deployment
			switchAction = initiator.userType;
			username = initiator.userName;
		}

		// if none of them
		if (!switchAction || !username) {
			return 'N/A';
		}

		// bots without vcsProvider
		const isUsernameBot = username.endsWith(VCS_CONTROL_MONKEY.BOT_TOKEN);
		const isUsernameControlMonkeyBot = username === VCS_CONTROL_MONKEY.GITHUB_BOT;

		switch (switchAction) {
			case USER_TYPE.controlMonkeyUser:
			case USER_TYPE.controlMonkeySsoUser:
				return username;
			case USER_TYPE.controlMonkeyProgrammaticUser:
				return `🤖 ${username}`;
			case USER_TYPE.githubUser:
			case EXTERNAL_INITIATOR_SOURCE.GITHUB: {
				// if vcsProvider is finally set return profile link from VCS provider 
				if (vcsProvider) {
					return this.getProfileLinkFromVCSProvider(initiator, vcsProvider);
				}

				if (isUsernameControlMonkeyBot) {
					return (
						<div className="d-flex align-items-center">
							<Avatar size="small" shape="circle" src={VCS_CONTROL_MONKEY.GITHUB_BOT_PROFILE_URL} />{" "}
							control-monkey (GitHub Bot)
						</div>
					);
				} else if (isUsernameBot) {
					const botUsername = username.split(VCS_CONTROL_MONKEY.BOT_TOKEN)[0];
					return `${botUsername} (GitHub Bot)`;
				}

				const userLabel = `${username} (GitHub)`;
				if (skipLink) {
					return userLabel;
				}

				return (
					<a href={`https://github.com/${username}`} target="_blank" rel="noreferrer">
						<Avatar size="small" shape="circle" src={`https://github.com/${username}.png`} />{" "}
						{userLabel}
					</a>
				);
			}
			case USER_TYPE.gitlabUser:
			case EXTERNAL_INITIATOR_SOURCE.GITLAB: {
				// if vcsProvider is finally set return profile link from VCS provider 
				if (vcsProvider) {
					return this.getProfileLinkFromVCSProvider(initiator, vcsProvider);
				}

				const userLabel = `${username} (Gitlab)`;
				if (skipLink) {
					return userLabel;
				}
				return (
					<a href={`https://gitlab.com/${username}`} target="_blank" rel="noreferrer">
						{userLabel}
					</a>
				);
			}
				
			case USER_TYPE.bitbucketUser:
			case EXTERNAL_INITIATOR_SOURCE.BITBUCKET:
				// if vcsProvider is finally set return profile link from VCS provider 
				if (vcsProvider) {
					return this.getProfileLinkFromVCSProvider(initiator, vcsProvider);
				}

				const userLabel = `${username} (BitBucket)`;
				if (skipLink) {
					return userLabel;
				}
				//https://bitbucket.org/workspaces/tamtam-nl/avatar/?ts=1673948556
				return (
					<a href={`https://bitbucket.org/${username}`} target="_blank" rel="noreferrer">
						{userLabel}
					</a>
				);
			case USER_TYPE.bitbucketDataCenterUser:
				// if vcsProvider is finally set return profile link from VCS provider 
				if (vcsProvider) {
					return this.getProfileLinkFromVCSProvider(initiator, vcsProvider);
				}
				return username + " (Bitbucket Data Center User)";
			case USER_TYPE.azureDevopsUser:
			case EXTERNAL_INITIATOR_SOURCE.AZURE_DEVOPS:
				// if vcsProvider is finally set return profile link from VCS provider 
				if (vcsProvider) {
					return this.getProfileLinkFromVCSProvider(initiator, vcsProvider);
				}
				return username + " (Azure DevOps User)";
			default:
				return "N/A";
		}
	}

	static getTriggerValToDisplay = (record, shortName = false) => {
		let { triggerInfo } = record;
		let reqNum = triggerInfo.githubPullRequestNumber || triggerInfo.gitlabMergeRequestIid || triggerInfo.bitbucketPullRequestId || triggerInfo.azureDevopsPullRequestId || triggerInfo.bitbucketDataCenterPullRequestId;
		if (shortName) {
			return `${(triggerInfo.gitlabMergeRequestIid ? 'MR' : 'PR')} ${reqNum}`
		}
		return (triggerInfo.gitlabMergeRequestIid ? 'Merge' : 'Pull') + ' Request ' + reqNum;
	}

	static getProviderTypeToDisplay = (providerType, shortName = false) => {
		const isMr = providerType === VCS_PROVIDER.GITLAB_ONPREM || providerType === VCS_PROVIDER.GITLAB_COM;
		if (shortName) {
			return `${(isMr ? 'MR' : 'PR')}`
		}
		return (isMr ? 'Merge' : 'Pull') + ' Request ';
	}

	// Method to compare two versions.
	// Returns 1 if v2 is smaller, -1
	// if v1 is smaller, 0 if equal
	static versionCompare(v1 = "", v2 = "") {
		// vnum stores each numeric
		// part of version
		var vnum1 = 0, vnum2 = 0;

		// loop until both string are
		// processed
		for (var i = 0, j = 0; (i < v1.length
			|| j < v2.length);) {
			// storing numeric part of
			// version 1 in vnum1
			while (i < v1.length && v1[i] != '.') {
				vnum1 = vnum1 * 10 + (v1[i] - '0');
				i++;
			}

			// storing numeric part of
			// version 2 in vnum2
			while (j < v2.length && v2[j] != '.') {
				vnum2 = vnum2 * 10 + (v2[j] - '0');
				j++;
			}

			if (vnum1 > vnum2)
				return 1;
			if (vnum2 > vnum1)
				return -1;

			// if equal, reset variables and
			// go for next numeric part
			vnum1 = vnum2 = 0;
			i++;
			j++;
		}
		return 0;
	}

	static formatDate(date) {
		let retVal = moment(date).format(dateFormat);
		return retVal;
	}

	static formatMS(ms = 0) {
		if (!ms) {
			return "";
		}

		const seconds = Math.trunc(ms/1000);
		if (seconds === 0) {
			return "< 1s";
		}

		const minutes = Math.trunc(seconds/60);
		if (minutes > 59) {
			const hours = Math.trunc(minutes/60);
			return `${hours}h ${minutes % 60}m ${seconds % 60}s`;
		} else if (seconds > 59) {
			return `${minutes}m ${seconds % 60}s`;
		} else {
			return `${seconds}s`;
		}
	}

	static isDiffHoursFromNow = (unixDate, hours) => {
		// check if given date is smaller then now() in x hours
		const checkDate = moment.unix(unixDate);
		const currentDateTime = moment();
		const hoursDiff = currentDateTime.diff(checkDate, "hours");
		if (hoursDiff > hours) {
			return true;
		}

		return false;
	}

	static generateCloudTrailLink = (region, eventId) => {
		if (!region || !eventId) {
			return null;
		}

		const finalRegion = region === 'aws-global' ? 'us-east-1' : region;
	
		return `https://console.aws.amazon.com/cloudtrail/home?region=${finalRegion}#/events/${eventId}`;
	}

	static findExistingItemAndExcludeFromArray = (findItem, array) => {
		let itemExist = false;
		const filteredArray = array.filter((i) => {
			if (i === findItem) {
				itemExist = true;
				return false;
			}
			return true;
		});

		return [itemExist, filteredArray];
	}

	// This method returns the original request JSON payload that was sent with the fetch request
	static extractRequestFromRejectedPromiseResult = (result) => {
		// Accepts a rejected promise fetch result when calling Promise.allSettled()
		// result.reason only exists on rejected promise
		// result.value only exists on fulfilled promise

		// To get the response error messages from the console-backend
		// result.reason.response.data.response.errors[0].message

		// more info
		// Promise.allSettled - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled#return_value
		// Promise - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise#promise_concurrency

		// In case of requests with no body (delete/get) we need to default to empty object

		return JSON.parse(result?.reason?.config?.data || '{}');
	}

	// this method returns a sort function for antd table for variables by the specified order
	static sortVariablesScope = (a, b) => {
		// these are the values scope property returns form server
		const order = {
			stack: 0,
			template: 1,
			namespace: 2,
			organization: 3
		};

		return (order[a.scope] - order[b.scope]) || a.scope.localeCompare(b.scope);
	}

	static getIaCState = (resource) => {
		// if iacState exist return it
		if (resource?.iacState) {
			if (('disasterRecoveryProperties' in resource)) {
				// this resource is documented
				if (resource.iacState === IAC_STATE_STATUS.MANAGED) {
					return IAC_STATE_STATUS.MANAGED_DOCUMENTED
				} else if(resource.iacState === IAC_STATE_STATUS.UNMANAGED) {
					return IAC_STATE_STATUS.UNMANAGED_DOCUMENTED
				}
			}
			return resource.iacState;
		}

		// does not exist but it is deleted, return N/A
		if (resource?.isDeleted) {
			return IAC_STATE_STATUS.NOT_AVAILABLE;
		}

		// it is not deleted but no state then pending calculation
		return IAC_STATE_STATUS.PENDING_CALCULATION;
	}

	static getIacStateStatus = (resource) => {
		const isManaged = [IAC_STATE_STATUS.MANAGED, IAC_STATE_STATUS.MANAGED_DOCUMENTED].includes(resource.iacState);
		const isUnmanaged = [IAC_STATE_STATUS.UNMANAGED, IAC_STATE_STATUS.UNMANAGED_DOCUMENTED].includes(resource.iacState);
		const isDocumented = [IAC_STATE_STATUS.MANAGED_DOCUMENTED, IAC_STATE_STATUS.UNMANAGED_DOCUMENTED].includes(resource.iacState);
	
		return {
			isManaged,
			isUnmanaged,
			isDocumented
		};
	};

	// this method returns a sort function for antd table for resource explorer default sort by IaC State
	static sortIaCStateDefault = (a, b) => {
		const idxA = Object.values(IAC_STATE_STATUS).indexOf(a.iacState);
		const idxB = Object.values(IAC_STATE_STATUS).indexOf(b.iacState);

		return (idxA - idxB) || a.iacState.localeCompare(b.iacState);
	}

	static sortControlPoliciesDefault = (a, b) => {
		// sort by ALL namespaces to be first
		const aMapping = a?.mapping || [];
		const bMapping = b?.mapping || [];
		let targetAllIdxA = 0;
		let targetAllIdxB = 0;
		if (aMapping.length === 0) {
			targetAllIdxA = -1;
		} else {
			const isAllMappedIdx = aMapping.findIndex((i) => i.targetId === CONTROL_POLICIES_TARGETS_ALL_NAMESPACES_OPTION.id);
			if (isAllMappedIdx > -1) {
				targetAllIdxA = 1;
			}
		}

		if (bMapping.length === 0) {
			targetAllIdxB = -1;
		} else {
			const isAllMappedIdx = bMapping.findIndex((i) => i.targetId === CONTROL_POLICIES_TARGETS_ALL_NAMESPACES_OPTION.id);
			if (isAllMappedIdx > -1) {
				targetAllIdxB = 1;
			}
		}

		// sort by ALL namespaces to be first, then mappings array length, then policy type (managed first) and finally by name a-z
		return (targetAllIdxB - targetAllIdxA) || (bMapping.length - aMapping.length) || a.policyType.localeCompare(b.policyType) || a.name.localeCompare(b.name);
	}

	static isNamespaceOnlyRoleView = (orgUserRole) => {
		if (orgUserRole === ROLE.NS_ONLY.key) {
			return true;
		} else if (orgUserRole === ROLE.ADMIN.key || orgUserRole === ROLE.VIEWER.key || orgUserRole === ROLE.MEMBER.key) {
			return false;
		}
		// defult case is namespace only in case orgUserRole is undefined
		return true;
	}

	static isAdminRoleView = (orgUserRole) => {
		return orgUserRole === ROLE.ADMIN.key;
	}

	static getOrganizationType = () => {
		let organization = localStorageHelper.getOrganization();
		try {
			organization = JSON.parse(organization);
		} catch (e) {
			// this should not happen, but just in case
			console.log('Warning: Could not parse organization data!');
			organization = {}
		}
		
		let organizationType = organization?.type;
		// in case organization type is unknown/not set we return "regular" type instead
		const allOrganizationTypes = Object.values(ORGANIZATION_SETTINGS.TYPE);
		if (!organizationType || !allOrganizationTypes.includes(organizationType)) {
			console.log("Warning: Organization type is unknown/not set!");
			organizationType = ORGANIZATION_SETTINGS.TYPE.REGULAR;
		}

		return organizationType;
	}

	static isOrganizationManagement = () => {
		const organizationType = this.getOrganizationType();
		return organizationType === ORGANIZATION_SETTINGS.TYPE.MANAGEMENT;
	}

	// get first time initial default provider utillity
	static getDefaultProvider = (cloudCreds) => {
		let defaultProvider = CLOUD_CREDS_PROVIDER_TYPE.AWS;
		const awsAccountList = cloudCreds[CLOUD_CREDS_PROVIDER_TYPE.AWS];
		const awsAccountListCount = awsAccountList.length;
		const azureSubscriptionList = cloudCreds[CLOUD_CREDS_PROVIDER_TYPE.AZURE]
		const azureSubscriptionListCount = azureSubscriptionList.length;
		const gcpProjectList = cloudCreds[CLOUD_CREDS_PROVIDER_TYPE.GCP]
		const gcpProjectListCount = gcpProjectList.length;
		
		// default provider by priority
		if (awsAccountListCount > 0) {
			// set to AWS
			defaultProvider = CLOUD_CREDS_PROVIDER_TYPE.AWS;
		} else if (azureSubscriptionListCount > 0) {
			// set to Aure
			defaultProvider = CLOUD_CREDS_PROVIDER_TYPE.AZURE;
		} else if (gcpProjectListCount > 0) {
			// set to GCP
			defaultProvider = CLOUD_CREDS_PROVIDER_TYPE.GCP;
		}

		return defaultProvider;
	}

	// get first time user dashboard to redirect to
	static getDefaultDashboardURL = (cloudCreds) => {
		let defaultProvider = this.getDefaultProvider(cloudCreds);
		if (defaultProvider === CLOUD_CREDS_PROVIDER_TYPE.AWS) {
			return ROUTES.AWS_DASHBOARD;
		} else if (defaultProvider === CLOUD_CREDS_PROVIDER_TYPE.AZURE) {
			return ROUTES.AZURE_DASHBOARD;
		} else if (defaultProvider === CLOUD_CREDS_PROVIDER_TYPE.GCP) {
			return ROUTES.GCP_DASHBOARD;
		}

		console.log('Warning: Could not get default dashboard for provider, defaulting to AWS');
		// in case there is an issue with the provider, not found or was not added yet
		// default if not found - AWS
		return ROUTES.AWS_DASHBOARD
	}

	static sortByCreatedAt = (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix();

	static getUserEmail = () => {
		let user = localStorageHelper.getUser();
		try {
			user = JSON.parse(user);
		} catch (e) {
			// this should not happen, but just in case
			console.log('Warning: Could not parse user data!');
			user = {};
		}

		return user?.email;
	}

	static pathAllowedByOrganizationType = (path, orgType) => {
		// get allowed routes by org type, if not found then default to AWS allowed routes
		const allowedRoutes = ORGANIZATION_ALLOWED_ACL_ROUTES?.[orgType] || ORGANIZATION_ALLOWED_ACL_ROUTES[ORGANIZATION_SETTINGS.TYPE.REGULAR];
		return allowedRoutes.includes(path);
	}

	static allowedByUserTypeAndPath = (path, orgUserRole) => {
		// User Type Check
		// by default it is allowed ONLY if user type has specific ACL role in USER_ALLOWED_ACL_ROUTES
		let isAllowed = true;
		const allowedRoutes = USER_ALLOWED_ACL_ROUTES?.[orgUserRole];
		if (allowedRoutes && Array.isArray(allowedRoutes)) {
		  isAllowed = allowedRoutes.includes(path);
		}

		// Path Check
		if (isAllowed) {
			const allowedUserTypesForPath = ALLOW_USER_TYPES_BY_PATH?.[path];
			if (Array.isArray(allowedUserTypesForPath)) {
				// path is defined for specific users, do the check on the users array
				isAllowed = allowedUserTypesForPath.includes(orgUserRole);
			}
		}

		return isAllowed;
	}

	static getDefaultHomePage = (orgUserRole) => {
		const isOrgManagement = this.isOrganizationManagement();
		let defaultHomePage = ORG_USER_HOME_PAGE.DEFAULT;

		if (isOrgManagement) {
		  defaultHomePage = ORG_USER_HOME_PAGE[ORGANIZATION_SETTINGS.TYPE.MANAGEMENT];
		}
		
		// also check by user type/role
		if (ORG_USER_HOME_PAGE?.[orgUserRole]) {
		  defaultHomePage = ORG_USER_HOME_PAGE[orgUserRole];
		}

		return defaultHomePage;
	}

	// this method calculate TTL termination time
	static calcTTLTerminationTime = (terminationTime) => {
		const now = moment();
		let then = moment(terminationTime);
		const diffInSeconds = then.unix() - now.unix();
		if (diffInSeconds > 0 && diffInSeconds < 119) {
			// if the gap is < 2 min show 1 minute string
			return ["Termination time in 1 minute", diffInSeconds]; // empty array will stop from interval! fix this
		} else if (then.isSameOrBefore(now)) {
			const diffInHours = Math.abs((diffInSeconds / 60) / 60);
			if (diffInHours > 1) {
				return [ONE_HOUR_SINCE_TERMINATION_TIME, diffInSeconds];
			}
			return ["Termination time has passed", diffInSeconds];
		}
	
		// time is larger then 2 min, add up 1 min for display (in case of 2.xx - 2 min and xx seconds left)
		then.add("1", "minute");
		const diff = then.diff(now);
		const dur = moment.duration(diff);
	
		const parts = [];
		for (const part of ["days", "hours", "minutes"]) {
			const d = dur[part]();
			dur.subtract(moment.duration(d, part));
			parts.push(d);
		}
	
		let [days, hours, minutes] = parts;
		const stringParts = [];
		if (days > 0) {
			stringParts.push(days === 1 ? "1 day" : `${days} days`);
		}
	
		if (hours > 0) {
			stringParts.push(hours === 1 ? "1 hour" : `${hours} hours`);
		}
	
		if (minutes > 0) {
			stringParts.push(minutes === 1 ? "1 minute" : `${minutes} minutes`);
		}
	
		const finalStr = `Termination time in ${stringParts.slice(0, 2).join(" and ")}`;
	
		return [finalStr, diffInSeconds];
	};

	// this method receives an array of ttlConfigs and returns the minimum TTL configuration
	static findMinimumTTLConfig = (ttlConfigs = []) => {
		let findMinTtlConfig;
		ttlConfigs
			.filter((i) => i)
			.forEach((i) => {
				if (!findMinTtlConfig) {
					findMinTtlConfig = i;
					return;
				}
	
				if (findMinTtlConfig.type === i.type) {
					// equal type
					findMinTtlConfig = findMinTtlConfig.value > i.value ? i : findMinTtlConfig;
				} else if (findMinTtlConfig.type === TTL.TYPE.HOURS.VALUE) {
					// current min TTL type is in hours, i iterator value is in days
					if (i.value * 24 < findMinTtlConfig.value) {
						findMinTtlConfig = i;
					}
				} else if (findMinTtlConfig.type === TTL.TYPE.DAYS.VALUE) {
					// current min TTL type is in days, i iterator value is in hours
					if (i.value < findMinTtlConfig.value * 24) {
						findMinTtlConfig = i;
					}
				}
			});
	
		return findMinTtlConfig;
	};

	static semVerTransformVersions = (semVerArr = []) => {
		// this method accepts an array of string of x.y.z sem ver and adds a new version .LATEST
		const semVerMap = {};
		semVerArr.forEach(i => {
			const semVer = i.split('.');
			const [major, minor, patch] = semVer;
			if (!(major in semVerMap)) {
				semVerMap[major] = {};
			}
		
			if (!(minor in semVerMap[major])) {
				semVerMap[major][minor] = {};
			}

			// not important the value but the key tree
			semVerMap[major][minor][patch] = i;
		});

		const versionsKeyVal = [];
		var majorNums = Object.keys(semVerMap);
		majorNums.forEach(majorI => {
			const minorNums = Object.keys(semVerMap[majorI]);
			minorNums.forEach(minorI => {
				const patchNums = Object.keys(semVerMap[majorI][minorI]);
				patchNums.forEach(patchI => {
					// key and value are the same for specific supported version
					const keyVal = `${majorI}.${minorI}.${patchI}`;
					versionsKeyVal.push({
						key: keyVal,
						value: keyVal
					});
				});
				// add our latest custom version to this patch iteration
				const key = `^${majorI}.${minorI}.`;
				const value = `${majorI}.${minorI}.LATEST`
				versionsKeyVal.push({key, value});
			});

			// add our latest custom version to the minor iteration
			const key = `^${majorI}.`;
			const value = `${majorI}.LATEST`;
			versionsKeyVal.push({key, value});
		});
		// add our latest custom version to the major iteration
		versionsKeyVal.push({key: '$LATEST', value: 'LATEST'});
		// keep original order
		return versionsKeyVal.reverse();
	}

	static objectContainNonNullValues = (obj = {}) => {
		// will return false if obj is undefined
		const objValues = Object.values(obj);
		return objValues.some((v) => v !== null);
	}

	// build CSV array from headers and data
	static buildCSV = (headers, data) => {
		const csvRows = [];
		// CSV first row is headers
		csvRows.push(headers.join(','));
	
		// CSV data rows
		data.forEach((row) => {
			csvRows.push(Object.values(row).join(','));
		});
	
		// finalize CSV
		return csvRows.join('\n');
	}
	
	// from: https://github.com/ant-design/ant-design/issues/6941#issuecomment-600803884
	// the sorting part didn't work so we need to update table onChange event to save the sort state
	// and send it to the export component which calls this function
	// if sortColumn = null it means its on initial stage 
	static filterAndSortTableData = (records, columns, sortColumn = {}) => {
		// some columns have default sort order
		const columnsDefaultSort = [];
		// checking if sort column is set, finding it for sorting
		const sortColumnName = sortColumn?.name;
		const sortColumnOrder = sortColumn?.order;
		let sortByColumn;
		let sortByColumnSorter;
		if (sortColumnName && sortColumnOrder) {
			sortByColumn = columns.find((c) => c.key === sortColumnName);
			sortByColumnSorter = sortByColumn?.sorter;
		} else if (sortColumn === null) {
			// no columns were clicked to be sorted (initial table load), but we need to consider column default sort order property
			columns.forEach((col) => {
				if (col.defaultSortOrder) {
					columnsDefaultSort.push({name: col.key, order: col.defaultSortOrder, sorter: col.sorter});
				}
			});
		}

		return records
			// this is for the "filter" of Table component (not in use "yet" in our tables)
			.filter((record) => {
				for (let col of columns) {
					const { filteredValue, onFilter } = col;
					if (onFilter && filteredValue && filteredValue.length > 0) {
						if (filteredValue.filter((val) => onFilter(val, record)).length === 0) return false;
					}
				}
				return true;
			})
			// sorting the data if we have sortByColumn set
			.sort((a, b) => {
				// if we found the column and it has a sort function we also sort by the correct order
				if (sortByColumn && sortByColumnSorter) {
					if (sortColumnOrder === 'ascend') {
						return sortByColumnSorter(a, b);
					} else if (sortColumnOrder === 'descend') {
						return sortByColumnSorter(b, a)
					}
				} else {
					// check for columns default sort order
					// iterate all columns and start sorting
					for (let i = 0; i < columnsDefaultSort.length; i++) {
						const { name, order, sorter } = columnsDefaultSort[i];
						let comparisonResult;
						if (order === 'ascend') {
							comparisonResult = sorter(a, b);
						} else if (order === 'descend') {
							comparisonResult = sorter(b, a);
						}
						if (comparisonResult !== 0) return comparisonResult; // If there's a difference, return immediately
					}
				}
	
				return 0;
			})
			.map((record, idx) => {
				const finalRecord = {};
	
				for (const col of columns) {
					const propName = col.dataIndex || col.key;
					if (propName) {
						const text = record[propName];
						// we check if we have specific export property to use it instead of default render column property
						if (col.export) {
							finalRecord[propName] = col.export(text, record, idx);
						} else {
							finalRecord[propName] = text;
						}
					}
				}
	
				return finalRecord;
			});
	};

	// returns a link or null for a specific resource type and resource id
	// only resources that are defined in the constant should have a link
	static auditResourceTypeLink = (resource) => {
		const {resourceType, resourceId} = resource;
		let link = AUDIT_RESOURCE_TYPES_LINK[resourceType];
		if (link && resourceId) {
			// if ":id" exists in link string, replace with resourceId
			if (link.includes(':id')) {
				link = link.replace(':id', resourceId.split('/')[0]); // make sure to take the first part if "/" is in the string
			}

			return link;
		}

		// no link
		return null;
	}

	static resourceNameTrim = (text = '', len = 60) => (text.length > len ? text.slice(0, len) + "..." : text);

	static findResourceCodeLocationRender = (vcsProviders = [], record) => {
		// this is to render code location of a resource
		// assume resource is not managed, we check the record data and set it
		let resourceLocation = {
			type: 'notFound'
		}
		
		// this code is similar to findStack() but only for use in export
		if (record.managementReference) {
			
			// VCS info can be for potential stacks and also stacks, that's why it's not part of the if-else
			if (record.managementReference.vcsInfo) {
				resourceLocation.type = 'vcsInfo';
				// Find the vcs provider
				// let vcsProvider = utils_lodash.find(vcsProviders, { 'id': });
				const vcsProvider = vcsProviders.find((vcsProvider) => (vcsProvider.id === record.managementReference.vcsInfo.providerId));
				if (vcsProvider) {
					resourceLocation.vcsLink = this.buildVcsUrl(
						vcsProvider,
						record.managementReference.vcsInfo,
						record.managementReference.codeLocation?.fileName,
						record.managementReference.codeLocation?.lineNumber);
				}

				if (!resourceLocation.vcsLink) {
					resourceLocation = {
						type: 'notFound'
					}; // fix bug, make it empty object to keep the "Find Locaition" button
				}
			}

			if (record.managementReference.stackId) {
				// stack - shouldn't happen since we should get the vcsInfo - unless it's a stack created from template
				resourceLocation.type = 'stack';
				resourceLocation.stackId = record.managementReference.stackId;
				resourceLocation.stackName = record.managementReference?.stackName;
			} else if (record.managementReference.stateFileId) {
				// State file
				resourceLocation.type = 'stateFile'
				resourceLocation.stateFile = record.managementReference.stateFileId;
			} else if (record.managementReference.cfnStackId) {
				// Cloud formation
				resourceLocation.type = 'cfnStack'
				resourceLocation.cfnStackId = record.managementReference.cfnStackId;
			} else if (record.managementReference.managedTags && Array.isArray(record.managementReference.managedTags)) {
				resourceLocation.type = 'tags';
				resourceLocation.tags = record.managementReference.managedTags;
			}
		}

		if (resourceLocation.type === 'notFound') {
			// we use empty spaces because if the table does not contain ANy element with codelocation the column name will break
			// using whitespace-nowrap on the column does not work with tables with scroll property of max-content
			return <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;
		} else if (resourceLocation.type === 'stack') {
			return (
				<span>
					<div>ControlMonkey Stack: </div>
					<a href={STACK_VIEW_PATH + "/" + resourceLocation.stackId} target="_blank" rel="noopener noreferrer">
							{resourceLocation.stackName ? resourceLocation.stackName : resourceLocation.stackId}
					</a>

					{resourceLocation.vcsLink && <div>VCS Location:<br /><a href={resourceLocation.vcsLink} target="_blank" rel="noopener noreferrer">Goto Code</a></div>}
				</span>
			)
		} else if (resourceLocation.type === 'stateFile') { // TF State file
			return (
				<span>
					State file location:<br />
					{resourceLocation.stateFile}
				</span>
			);
		} else if (resourceLocation.type === 'cfnStack') { // Cfn stack
			return (
				<span>
					<div>CloudFormation Stack: </div>
					<a href={"https://console.aws.amazon.com/cloudformation/home?region=" + this.getRegionFromArn(resourceLocation.cfnStackId) + "#/stacks/resources?stackId=" + resourceLocation.cfnStackId} target="_blank" rel="noopener noreferrer">
						{this.getCfnStackNameFromArn(resourceLocation.cfnStackId)}
					</a>
				</span>
			);
		} else if (resourceLocation.type === 'vcsInfo') {
			if (resourceLocation.vcsLink) {
				return (
					<div>VCS Location:<br />
						<a href={resourceLocation.vcsLink} target="_blank" rel="noopener noreferrer">
							Goto Code
						</a>
					</div>
				);
			} else {
				return (
					<div>
						<span><b>Repo:</b> {resourceLocation.vcsInfo.repoName}</span>
					</div>
				);
			}
		} else if (resourceLocation.type === 'tags') {
			const tooltipTitle = resourceLocation.tags.map((tag, idx) => {
				return (
					<div key={idx} style={{whiteSpace: 'nowrap'}}>
						{tag.key && (<span><span className="font-weight-semibold">Key:{" "}</span>{tag.key}</span>)}
						{tag.key && tag.value && <span className="ml-2" />}
						{tag.value && (<span><span className="font-weight-semibold">Value:{" "}</span>{tag.value}</span>)}
					</div>
				);
			});

			return (
				<Tooltip title={tooltipTitle} overlayStyle={{maxWidth: '80vh'}}>Managed by tags</Tooltip>
			);
		}
	}

	static isEmailValid = (email) => {
		// taken from antd email validation - https://stackoverflow.com/a/75845210
		const validator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/
		return validator.test(email);
	}

	static mapPassedStatus = (row) => {
		let statusKey = CONTROL_POLICIES_STATUS.ERROR;
		if ('status' in row) {
			// we handle V2 status
			statusKey = row.status;
		} else {
			statusKey = row.hasPolicyChecksPassed ? CONTROL_POLICIES_STATUS.PASSED : CONTROL_POLICIES_STATUS.FAILED;
		}
	
		return statusKey;
	}

	static severityScoreFromRow = (row) => {
		const {enforcementLevel: el} = row;
		const status = this.mapPassedStatus(row);

		return this.severityScore(el, status);
	}

	static severityScore = (el, status) => {
		let score = 0;
		if (status === CONTROL_POLICIES_STATUS.FAILED && el === CONTROL_POLICIES_ENFORCEMENT_LEVEL.HARD_MANDATORY) {
			// failed,hardMandatory
			score = 9;
		} else if (status === CONTROL_POLICIES_STATUS.ERROR && el === CONTROL_POLICIES_ENFORCEMENT_LEVEL.HARD_MANDATORY) {
			// error,hardMandatory
			score = 8
		} else if (status === CONTROL_POLICIES_STATUS.FAILED && el === CONTROL_POLICIES_ENFORCEMENT_LEVEL.SOFT_MANDATORY) {
			// failed,softMandatory
			score = 7
		} else if (status === CONTROL_POLICIES_STATUS.ERROR && el === CONTROL_POLICIES_ENFORCEMENT_LEVEL.SOFT_MANDATORY) {
			// error,softMandatory
			score = 6
		} else if (status === CONTROL_POLICIES_STATUS.FAILED && el === CONTROL_POLICIES_ENFORCEMENT_LEVEL.WARNING) {
			// failed,warning
			score = 5
		} else if (status === CONTROL_POLICIES_STATUS.ERROR && el === CONTROL_POLICIES_ENFORCEMENT_LEVEL.WARNING) {
			// error,warning
			score = 4
		} else if (status === CONTROL_POLICIES_STATUS.PASSED && el === CONTROL_POLICIES_ENFORCEMENT_LEVEL.HARD_MANDATORY) {
			// passed,hardMandatory
			score = 3
		} else if (status === CONTROL_POLICIES_STATUS.PASSED && el === CONTROL_POLICIES_ENFORCEMENT_LEVEL.SOFT_MANDATORY) {
			// passed,softMandatory
			score = 2
		} else if (status === CONTROL_POLICIES_STATUS.PASSED && el === CONTROL_POLICIES_ENFORCEMENT_LEVEL.WARNING) {
			// passed,warning
			score = 1
		}
	
		return score;
	}

	/**
	 * Get first character from first & last sentences of a username
	 * @param {String} name - Username
	 * @return {String} 2 characters string
	 */
	static getNameInitial(name) {
		let initials = name.match(/\b\w/g) || [];
		return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
	}

	/**
	 * Get current path related object from Navigation Tree
	 * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
	 * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
	 * @return {Object} object that contained the path string
	 */
	static getRouteInfo(navTree, path) {
		if (navTree.path === path) {
			return navTree;
		}
		// some pages are "sub" paths of main pages so we check if they are included in the path
		if (navTree.subPaths && Array.isArray(navTree.subPaths)) {
			if (navTree.subPaths.some(subp => (path.indexOf(subp) > -1))) {
				return navTree;
			}
		}

		let route;
		for (let p in navTree) {
			if (navTree.hasOwnProperty(p) && typeof navTree[p] === 'object') {
				route = this.getRouteInfo(navTree[p], path);
				if (route) {
					return route;
				}
			}
		}
		return route;
	}

	/**
	 * Get accessible color contrast
	 * @param {String} hex - Hex color code e.g '#3e82f7'
	 * @return {String} 'dark' or 'light'
	 */
	static getColorContrast(hex) {
		if (!hex) {
			return 'dark'
		}
		const threshold = 130;
		const hRed = hexToR(hex);
		const hGreen = hexToG(hex);
		const hBlue = hexToB(hex);
		function hexToR(h) { return parseInt((cutHex(h)).substring(0, 2), 16) }
		function hexToG(h) { return parseInt((cutHex(h)).substring(2, 4), 16) }
		function hexToB(h) { return parseInt((cutHex(h)).substring(4, 6), 16) }
		function cutHex(h) { return (h.charAt(0) === '#') ? h.substring(1, 7) : h }
		const cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
		if (cBrightness > threshold) {
			return 'dark'
		} else {
			return 'light'
		}
	}

	/**
	 * Darken or lighten a hex color 
	 * @param {String} color - Hex color code e.g '#3e82f7'
	 * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
	 * @return {String} Darken or lighten color 
	 */
	static shadeColor(color, percent) {
		let R = parseInt(color.substring(1, 3), 16);
		let G = parseInt(color.substring(3, 5), 16);
		let B = parseInt(color.substring(5, 7), 16);
		R = parseInt(R * (100 + percent) / 100);
		G = parseInt(G * (100 + percent) / 100);
		B = parseInt(B * (100 + percent) / 100);
		R = (R < 255) ? R : 255;
		G = (G < 255) ? G : 255;
		B = (B < 255) ? B : 255;
		const RR = ((R.toString(16).length === 1) ? `0${R.toString(16)}` : R.toString(16));
		const GG = ((G.toString(16).length === 1) ? `0${G.toString(16)}` : G.toString(16));
		const BB = ((B.toString(16).length === 1) ? `0${B.toString(16)}` : B.toString(16));
		return `#${RR}${GG}${BB}`;
	}

	/**
	 * Convert RGBA to HEX 
	 * @param {String} rgba - RGBA color code e.g 'rgba(197, 200, 198, .2)')'
	 * @return {String} HEX color 
	 */
	static rgbaToHex(rgba) {
		const trim = str => (str.replace(/^\s+|\s+$/gm, ''))
		const inParts = rgba.substring(rgba.indexOf("(")).split(","),
			r = parseInt(trim(inParts[0].substring(1)), 10),
			g = parseInt(trim(inParts[1]), 10),
			b = parseInt(trim(inParts[2]), 10),
			a = parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2);
		const outParts = [
			r.toString(16),
			g.toString(16),
			b.toString(16),
			Math.round(a * 255).toString(16).substring(0, 2)
		];

		outParts.forEach(function (part, i) {
			if (part.length === 1) {
				outParts[i] = '0' + part;
			}
		})
		return (`#${outParts.join('')}`);
	}

	/**
	 * Returns either a positive or negative 
	 * @param {Number} number - number value
	 * @param {any} positive - value that return when positive
	 * @param {any} negative - value that return when negative
	 * @return {any} positive or negative value based on param
	 */
	static getSignNum(number, positive, negative) {
		if (number > 0) {
			return positive
		}
		if (number < 0) {
			return negative
		}
		return null
	}

	/**
	 * Returns either ascending or descending value
	 * @param {Object} a - antd Table sorter param a
	 * @param {Object} b - antd Table sorter param b
	 * @param {String} key - object key for compare
	 * @return {any} a value minus b value
	 */
	static antdTableSorter(a, b, key) {
		if (typeof a[key] === 'number' && typeof b[key] === 'number') {
			return a[key] - b[key]
		}

		if (typeof a[key] === 'string' && typeof b[key] === 'string') {
			a = a[key].toLowerCase();
			b = b[key].toLowerCase();
			return a > b ? -1 : b > a ? 1 : 0;
		}
		return
	}

	/**
	 * Filter array of object 
	 * @param {Array} list - array of objects that need to filter
	 * @param {String} key - object key target
	 * @param {any} value  - value that excluded from filter
	 * @return {Array} a value minus b value
	 */
	static filterArray(list, key, value) {
		let data = list
		if (list) {
			data = list.filter(item => item[key] === value)
		}
		return data
	}

	/**
	 * Remove object from array by value
	 * @param {Array} list - array of objects
	 * @param {String} key - object key target
	 * @param {any} value  - target value
	 * @return {Array} Array that removed target object
	 */
	static deleteArrayRow(list, key, value) {
		let data = list
		if (list) {
			data = list.filter(item => item[key] !== value)
		}
		return data
	}

	/**
	 * Wild card search on all property of the object
	 * @param {Number | String} input - any value to search
	 * @param {Array} list - array for search
	 * @return {Array} array of object contained keyword
	 */
	static wildCardSearch(list, input) {
		let wildCardRE = null;
		if (input.includes('*')) {
			wildCardRE = new RegExp(input.toUpperCase().replaceAll('*', '.*'))
		}
		const searchText = (item) => {
			for (let key in item) {
				if (item[key] == null) {
					continue;
				}

				if (wildCardRE) {
					// wildcard search
					if (item[key].toString().toUpperCase().match(wildCardRE)) {
						return true;
					}
				} else {
					// regular search
					if (item[key].toString().toUpperCase().indexOf(input.toString().toUpperCase()) !== -1) {
						return true;
					}
				}
				
			}
		};
		list = list.filter(value => searchText(value));
		return list;
	}

	// Similar to wildCardSearch but dedicated for nested search in an array of objects keys "values"
	static searchArrayObjectsNestedValues(array, searchString) {
		const visited = new WeakSet(); // To track visited objects and avoid cyclic references
		const lowerCaseSearchString = searchString.toLowerCase(); // Convert search string to lowercase
		const matches = []; // Array to store all matching objects

		function search(obj, lowerCaseSearchString) {
			if (visited.has(obj)) {
				return false; // Avoid cyclic references
			}
			visited.add(obj);

			let isMatch = false; // Track if current object is a match

			for (const key in obj) {
				if (obj.hasOwnProperty(key)) {
					const value = obj[key];

					if (typeof value === 'object' && value !== null) {
						if (search(value, lowerCaseSearchString)) {
							isMatch = true; // Match found in nested object
						}
					} else {
						if (String(value).toLowerCase().includes(lowerCaseSearchString)) {
							isMatch = true; // Match found in current property
						}
					}
				}
			}
			return isMatch;
		}

		for (const item of array) {
			if (search(item, lowerCaseSearchString)) {
				matches.push(item); // Add matching object to the results array
			}
		}

		return matches; // Return array of all matching objects
	}

	/**
	 * Get Breakpoint
	 * @param {Object} screens - Grid.useBreakpoint() from antd
	 * @return {Array} array of breakpoint size
	 */
	static getBreakPoint(screens) {
		let breakpoints = []
		for (const key in screens) {
			if (screens.hasOwnProperty(key)) {
				const element = screens[key];
				if (element) {
					breakpoints.push(key)
				}
			}
		}
		return breakpoints
	}

	// Calculate account's age in minutes
	static getTimeDiffInMinutes(timestamp) {
		let timestampObject = moment(timestamp);
		if (timestampObject.isValid()) {
			const currentDate = moment();
			const diffInMinutes = currentDate.diff(timestampObject, "minutes"); 
			return diffInMinutes;
		} else {
			// Handle the case where the createdAt timestamp is invalid
			console.log("Invalid creation date");
			return null;
		}
	}

	// Returns true if the policy group is regular(manged by customer)
	static isMangedControlPolicyGroup(controlPolicyGroup = {}) {
		const mangedControlPolicyGroup = controlPolicyGroup?.[CONTROL_POLICIES_MAPPING_KEY.CONTROL_POLICY_GROUP_ID];
		if (mangedControlPolicyGroup) {
			return mangedControlPolicyGroup.startsWith('polg');
		}

		return false;
	}

	static isValidJSON(jsonString) {
		try {
			JSON.parse(jsonString);
			return true;
		} catch (e) {
			return false;
		}
	}

	static isValidARNPrefix(arn ="") {
		// this is not a coplete ARN validation only for prefix
		const trimmedLowerCaseARN = arn.trim().toLowerCase();
		if (trimmedLowerCaseARN.startsWith("arn:aws:") || trimmedLowerCaseARN.startsWith("fictive-arn:aws:")) {
			return true;
		}
		return false;
	}
}

export default Utils;
