import axios from 'axios'
import { API_BASE_URL, ENTRY_ROUTE } from 'configs/AppConfig'
import { RESOURCES_TAGS_CM_POLICY_ERROR, RESOURCES_TAGS_SERVICE_ACTIVATION_ERROR, RESOURCES_TAGS_TEST_ENDPOINT_ERROR } from 'constants/AppConstant';
import history from '../history'
import { AUTH_TOKEN } from 'redux/constants/Auth'
import { notification } from 'antd';
import localStorageHelper from 'helpers/localStorageHelper';

const service = axios.create({
	baseURL: API_BASE_URL,
	timeout: 60000 * 10 // default 10 minutes timeout for every request. To override in a request, set timeout property next to method and url
})

// Config

const TOKEN_PAYLOAD_KEY = 'Authorization'
const PUBLIC_REQUEST_KEY = 'public-request'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN)

	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + jwtToken;
	}

	if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
		history.push(ENTRY_ROUTE)
		window.location.reload();
	}

	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
	Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use((response) => {
	return response.data
}, (error) => {

	let notificationParam = {
		message: undefined
	}

	if (!('response' in error)) {
		// Error is related to browser only, if connection was aborted/blocked/timeout etc
		notification.error({message: 'Something went wrong'});
		return Promise.reject(error);
	}

	// Remove token and redirect 
	if (error.response.status === 401) {
		notificationParam.message = 'Authentication Fail'
		notificationParam.description = 'Please login again'
		localStorageHelper.onSignOut();
		history.push(ENTRY_ROUTE)
		window.location.reload();
	}

	if (error.response.status === 400) {
		let errorToDisplay = 'Error fulfilling request';
		if (error.response.data.response.errors &&
			error.response.data.response.errors.length > 0) {
			errorToDisplay = error.response.data.response.errors[0].message;

			// specific use case of errors we would like to hide the error message notification
			if (
				errorToDisplay === RESOURCES_TAGS_SERVICE_ACTIVATION_ERROR ||
				errorToDisplay === RESOURCES_TAGS_CM_POLICY_ERROR ||
				errorToDisplay === RESOURCES_TAGS_TEST_ENDPOINT_ERROR
			) {
				return Promise.reject(errorToDisplay);
			}
		}

		console.log('error', error.response.data.response.errors);
		notificationParam.message = errorToDisplay;
	}

	if (error.response.status === 403) {
		notificationParam.message = 'User is not authorized to perform this action'
	}

	if (error.response.status === 404) {
		notificationParam.message = 'Not Found'
	}

	if (error.response.status === 500) {
		notificationParam.message = 'Internal Server Error'
	}

	if (error.response.status === 508) {
		notificationParam.message = 'Time Out'
	}

	if (notificationParam.message === undefined) {
		notificationParam.message = "Something went wrong"
	}

	notification.error(notificationParam)

	return Promise.reject(error);
});

export default service